/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */

import { api } from '@badam/common-utils'
import { reportToLark } from '@/api'
import store from '@/store'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { customAlphabet } from 'nanoid'
import { kLoginSource } from './step-report'
import {
  LOGIN_TICKET_KEY,
  LOGIN_TOKEN_KEY,
  LOGIN_TYPE_KEY,
  NANO_ID_KEY,
  USER_INFO_KEY,
  FINGER_KEY,
  LAST_INPUT_ID,
  PAY_INPUT_SOURCE,
} from './consts'

const fpPromise = FingerprintJS.load()
initFinger()

interface UserInfo {
  display_uid: number
  nickname: string
  coin: number
  icon: string
  session_key: string
  currency: string
  rate: number
  rebate: number
  type: number
  uid: number
  role: number
}

export function getToken() {
  return getValue(LOGIN_TOKEN_KEY) || store.state.session_key || ''
  // return store.state.session_key || ''
  // return Cookies.get(LOGIN_TOKEN_KEY) || ''
}

export function setToken(token: string) {
  store.state.session_key = token
  setValue(LOGIN_TOKEN_KEY, token)
  // return Cookies.set(LOGIN_TOKEN_KEY, token)
}

export function removeToken() {
  store.state.session_key = ''
  removeValue(LOGIN_TOKEN_KEY)
  // return Cookies.remove(LOGIN_TOKEN_KEY)
}

export function getUserInfo() {
  return getValue(USER_INFO_KEY) || {}
  // return JSON.parse(Cookies.get(USER_INFO_KEY) || JSON.stringify({} as UserInfo))
}

export function getUid() {
  return getUserInfo().uid || ''
}

export function setUserInfo(userInfo: UserInfo) {
  setValue(USER_INFO_KEY, userInfo)
  // return Cookies.set(USER_INFO_KEY, JSON.stringify(userInfo))
}

export function removeUserInfo() {
  removeValue(USER_INFO_KEY)
  // return Cookies.remove(USER_INFO_KEY)
}

export function getLoginDisplayUID() {
  const u = getUserInfo()
  if (!u) {
    return ''
  }
  return u.display_uid
}

export function setValue(k: string, v: any) {
  // value -> json -> base64
  try {
    const a = JSON.stringify(v)
    const b = a
    localStorage.setItem(k, b)
  } catch (e) {
    console.warn('LocalStorage Error, set key:', k)
    reportToLark(`LocalStorage set value Error, key: ${k}`, `${e}\n${JSON.stringify(v)}}`)
  }
}

export function removeValue(k: string) {
  localStorage.removeItem(k)
}

export function setNanoid() {
  const v = getNanoid()
  if (!v) {
    const nanoid = customAlphabet('1234567890abcdef', 32)
    setValue(NANO_ID_KEY, nanoid())
  }
}

export function getNanoid() {
  const v = getValue(NANO_ID_KEY) || ''
  return v
}

export function getValue(k: string) {
  if (!k) {
    return ''
  }
  // base64 -> json -> value
  const b = localStorage.getItem(k)
  if (!b) {
    return ''
  }
  try {
    const a = b
    const v = JSON.parse(a)
    return v
  } catch (e) {
    console.warn('LocalStorage Error, get key:', k, `${e}`)
    reportToLark(`LocalStorage get value Error, key: ${k}`, `${e}`)
  }
  return null
}

export function initFinger() {
  const v = localStorage.getItem(FINGER_KEY)
  if (v) {
    return
  }
  ;(async () => {
    const fp = await fpPromise
    const result = await fp.get()
    localStorage.setItem(FINGER_KEY, result.visitorId)
  })()
}

export async function setFinger() {
  const fp = await fpPromise
  const result = await fp.get()
  localStorage.setItem(FINGER_KEY, result.visitorId)
  return getFinger()
}

export function getFinger() {
  // const key = localStorage.getItem(FINGER_KEY) || ''
  // return key
  return api.getUUid()
}

export function getLoginType() {
  return getValue(LOGIN_TYPE_KEY) || store.state.loginType
}

export function setLoginType(t: kLoginSource) {
  store.state.loginType = t
  setValue(LOGIN_TYPE_KEY, t)
}

export function removeLoginType() {
  store.state.session_key = ''
  removeValue(LOGIN_TYPE_KEY)
}

export function getTicketKey() {
  return getValue(LOGIN_TICKET_KEY)
}

export function setTicketKey(t: string) {
  setValue(LOGIN_TICKET_KEY, t)
}

export function getLastInputID() {
  return getValue(LAST_INPUT_ID) || ''
}

export function setLastInputID(t: number) {
  setValue(LAST_INPUT_ID, t)
}

export function removeLastInputID() {
  removeValue(LAST_INPUT_ID)
}

// eslint-disable-next-line no-shadow
export enum IPayInputSource {
  FROM_LOGIN = '默认填充登录账号',
  FROM_LAST = '默认填充上次充值账号',
  FROM_HANDLY_LOGIN = '手动选择登录账号',
  FROM_HANDLY_RENCENT = '手动选择最近充值账号',
}

export function getPayInputSource() {
  return getValue(PAY_INPUT_SOURCE) || ''
}

export function setPayInputSource(t: IPayInputSource) {
  setValue(PAY_INPUT_SOURCE, t)
}
