/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/no-extraneous-dependencies
import { api, getArgsFromUrl } from '@badam/common-utils'
import axios, { AxiosRequestConfig } from 'axios'
import Qs from 'qs'
import { getFinger } from '@/utils/token'
// import { showToast } from '@components/ui/toast.vue'
// import { _T } from '@/utils/lang'
// import { isSupport } from '@/utils/bridge'
// import { getSessionKey, awaitKinoJsApi, isIOS, getArgsFromUrl } from '../utils/func'

declare const window: any

declare const process: any
export interface ApiResult<T> {
  data: T
  result: number
  message?: string
}

let storageDebug

try {
  storageDebug = JSON.parse(localStorage.getItem('is_debug') || '0')
} catch (e) {
  storageDebug = 0
  console.warn(e)
}

const isDebug = getArgsFromUrl('is_debug') || storageDebug || 0

const isRelease = getArgsFromUrl('is_release') || 0

// api.getAxiosInstance().defaults.baseURL = isDebug
//   ? process.env.VUE_APP_PROXY_URL
//   : isRelease
//   ? process.env.VUE_APP_RELEASE_URL
//   : process.env.VUE_APP_BASE_URL

axios.defaults.baseURL = isDebug
  ? process.env.VUE_APP_PROXY_URL
  : isRelease
  ? process.env.VUE_APP_RELEASE_URL
  : process.env.VUE_APP_BASE_URL
// axios.defaults.baseURL = process.env.VUE_APP_PROXY_URL

export const instance = axios.create()

// 生成一个cancelToken类
const cancelToken = axios.CancelToken
let cancel

const pendingMap = new Map()

const addPending = (config: AxiosRequestConfig) => {
  // pendingMap.set()
}

axios.interceptors.request.use((config) => {
  const { url } = config
  return config
})

const accountsMap = {
  yuexi123: {
    openId: '8df587c9cde33a6d9134d4e34d924bb16f80a',
    token: '27c31e8f116d2dd41079a05f9b699601704bb669cd2d5b14b89d1a12f058a80c',
  },
  rebajiejie: {
    openId: '8df58b887dbe393eabe37d1675021d90b5f30',
    token: '9e9b7b3aeef367213d8489718ca23f80a61d1f35deb7f628ba46a0da2a7c4752',
  },
  rena: {
    openId: '8df5852d4352614c2d006cba255803df7e90d',
    token: '940f7dec18c2e9fe901fefb15b3c62b3b8dca2929592543ddef79e881f84041b',
  },
  wusu: {
    openId: '8df58b33813890cde113d7aee498f28b3813f',
    token: 'ae8b064b5956288829bf20bef4aa80444a600d70c78e76b5037425aa7326a187',
  },
  meishaonv47: {
    openId: '',
    token: '',
  },
}

const fixedHeaders = {
  // 'x-cli-pn': 'com.sawa.web.kanka',
  // 'x-cli-pn': process.env.VUE_APP_FLAVOR === 'ksa' ? 'com.sawa.ksa' : 'com.safaplay.app.safa',
  // 'x-cli-la': 'ar',
}

// let isInit = false

// isSupport()().then((res) => {
//   isInit = res
// })

// if (process.env.VUE_APP_BASE_URL) {
//   instance.defaults.baseURL = process.env.VUE_APP_BASE_URL
// }

instance.defaults.headers = fixedHeaders as any

const getExtraHeaders = () => {
  const br = window.JavaBridgeObj ? '1' : '0'

  return {
    'x-cli-id': getFinger(),
    'x-cli-pn': 'com.sawa.web.kanka',
    'x-cli-br': br,
    'x-cli-la': 'ar',
  }
}

export const get = async (url: any, params: any, headers = {}) => {
  headers = Object.getOwnPropertyNames(headers).length > 0 ? headers : fixedHeaders
  const rsp = await instance({
    method: 'GET',
    url,
    paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    params,
    headers: {
      ...headers,
      ...getExtraHeaders(),
    },
    data: Qs.stringify(params, { indices: false }),
  }).catch(
    (error: {
      config: any
      response: { data: any; status: any; headers: any }
      request: { toString: () => any }
      message: { toString: () => any }
    }) => {
      console.log(error.config)
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return {
          status: error.response.status,
          data: {
            result: error.response.status,
            message:
              typeof error.response.data === 'string' ? error.response.data : error.response.status,
          },
        }
      }
      if (error.request) {
        console.log(error.request)
        return {
          status: -2,
          data: { result: -2, message: error.request.toString() },
        }
      }
      console.log('Error', error.message)
      return {
        status: -3,
        data: { result: -3, message: error.message.toString() },
      }
    }
  )
  return [rsp.status === 200, rsp.data]
}

export const post = async (url: any, params: any, headers = {}) => {
  headers = Object.getOwnPropertyNames(headers).length > 0 ? headers : fixedHeaders
  const rsp = await instance({
    method: 'POST',
    url,
    paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    params,
    headers: {
      ...headers,
      ...getExtraHeaders(),
    },
    data: Qs.stringify(params, { indices: false }),
  }).catch(
    (error: {
      config: any
      response: { data: any; status: any; headers: any }
      request: { toString: () => any }
      message: { toString: () => any }
    }) => {
      console.log(error.config)
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return {
          status: error.response.status,
          data: {
            result: error.response.status,
            message:
              typeof error.response.data === 'string' ? error.response.data : error.response.status,
          },
        }
      }
      if (error.request) {
        console.log(error.request)
        return {
          status: -2,
          data: { result: -2, message: error.request.toString() },
        }
      }
      console.log('Error', error.message)
      return {
        status: -3,
        data: { result: -3, message: error.message.toString() },
      }
    }
  )
  return [rsp.status === 200, rsp.data]
}

export const postReq = async (url: any, params: any, headers = {}) => {
  headers = Object.getOwnPropertyNames(headers).length > 0 ? headers : fixedHeaders
  const rsp = await instance({
    method: 'POST',
    url,
    paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
      ...getExtraHeaders(),
    },
    data: Qs.stringify(params, { indices: false }),
  }).catch(
    (error: {
      config: any
      response: { data: any; status: any; headers: any }
      request: { toString: () => any }
      message: { toString: () => any }
    }) => {
      console.log(error.config)
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return {
          status: error.response.status,
          data: {
            result: error.response.status,
            message:
              typeof error.response.data === 'string' ? error.response.data : error.response.status,
          },
        }
      }
      if (error.request) {
        console.log(error.request)
        return {
          status: -2,
          data: { result: -2, message: error.request.toString() },
        }
      }
      console.log('Error', error.message)
      return {
        status: -3,
        data: { result: -3, message: error.message.toString() },
      }
    }
  )
  return [rsp.status === 200, rsp.data]
}

const isAudienceClient = () => {
  // return true
  if (!window.kinoJsApi) {
    return true
  }
  return !!window.kinoJsApi.initPay
}

function getArgNum(f: { toString: () => any }) {
  const code = f.toString()
  const aBegin = code.indexOf('(')
  if (aBegin < 0) {
    return 0
  }
  const aEnd = code.indexOf(')', aBegin + 1)
  if (aEnd < 0) {
    return 0
  }
  return code.substring(aBegin + 1, aEnd).split(',').length
}

function shouldCallWrapApi() {
  if (!window.kinoJsApi) {
    return false
  }
  if (!window.kinoJsApi.wrapApi) {
    return false
  }
  const size = getArgNum(window.kinoJsApi.wrapApi)
  if (isAudienceClient() && size <= 3) {
    return false
  }
  return true
}

// function wrapGet(path: any, params: { open_id: any; token: any }) {
//   let callback: any

//   const p = new Promise((resolve: any) => {
//     callback = resolve
//   })
//   if (params.open_id !== undefined) {
//     delete params.open_id
//   }
//   if (params.token !== undefined) {
//     delete params.token
//   }
//   if (isAudienceClient() || isIOS()) {
//     window.kinoJsApi.wrapApi('GET', path, params, callback)
//   } else {
//     window.kinoJsApi.wrapApi(path, params, callback)
//   }
//   return p
// }

// export const wget = async (path: any, params: any) => {
//   if (!isInit) {
//     await awaitKinoJsApi()
//   }
//   if (shouldCallWrapApi()) {
//     let ret: any = {}
//     try {
//       ret = await wrapGet(path, params)
//     } catch (e) {
//       return [false, { result: -4, message: e.toStirng() }]
//     }
//     return [true, ret]
//   }
//   const ret = await get(path, params)
//   return ret as unknown as Promise<[boolean, ApiResult<any>]>
// }

const $http = {
  instance,
  get,
  post,
  // wget,
}

function showToastMsg(msg: string, isAlert: boolean) {
  // eslint-disable-next-line no-unused-expressions
  // isAlert && showToast(msg)
}

export function handleApiResult<T>(res: [boolean, ApiResult<T>], isAlert = true) {
  if (!res[0]) {
    // showToastMsg(_T('获取数据失败，请重试'), isAlert)
    return
  }
  const r = res[1] || {}
  if (r.result === 1001) {
    // showToastMsg(_T('其他错误'), isAlert)
  }
  // eslint-disable-next-line consistent-return
  return r.result === 0
}

export default $http
