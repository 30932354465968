/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { getPlatform } from '@badam/common-utils'
import RouterAuthManager from './auth'

Vue.use(VueRouter)

interface OtherConfig {
  roles: []
}

const routes: Array<RouteConfig | OtherConfig> = [
  { path: '/pchome', name: 'pchome', redirect: '/' },
  { path: '/mobilehome', name: 'mobilehome', redirect: '/' },
  { path: '/pcpay', name: 'pcpay', redirect: '/pay' },
  { path: '/mobilepay', name: 'mobilepay', redirect: '/pay' },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index'),
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/pay'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.default'),
  },
  {
    path: '/fines',
    name: 'fines',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: () => import('@/views/fines/index.default'),
  },
  {
    path: '/mobilefines',
    name: 'mobilefines',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: () => import('@/views/fines/index.default'),
  },
  {
    path: '/mobilepay',
    name: 'mobilepay',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component: () => import('@/views/pay'),
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting'),
  },
  {
    path: '/share-landing',
    name: 'share-landing',
    component: () => import('@/views/share-landing'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue'),
  },
]

const mode = 'history'
const base = (process.env as any).VUE_APP_RUN_DEV
  ? '/'
  : process.env.VUE_APP_ENV === 'production'
  ? '/'
  : '/html/sawa-offical-website/ksa/'
const router = new VueRouter({
  mode,
  // base: `/${process.env.VUE_APP_SUFFIX}/`,
  base,
  routes: routes as RouteConfig[],
})

router.beforeEach((to, from, next) => {
  // if (mode === 'hash') {
  //   // 哈希路由下删除多余哈希
  //   if (to.hash) {
  //     const params = to.fullPath.split('?')[1]
  //     router.push(`${to.fullPath.replace(to.hash, '')}?${params}`)
  //   }
  //   // ?a=1#/pchome?a=1 这种情况的兼容
  //   if (Object.keys(to.query).length >= 1 && window.location.search) {
  //     router.push(to.fullPath.split('?')[0])
  //   }
  // }

  // if (getPlatform().isPc && to.fullPath.indexOf('mobile') !== -1) {
  //   router.push(to.fullPath.replaceAll('mobile', 'pc'))
  // }
  // if (!getPlatform().isPc && to.fullPath.indexOf('pc') !== -1) {
  //   router.push(to.fullPath.replaceAll('pc', 'mobile'))
  // }
  RouterAuthManager.getInstance().handleBeforeEach(to, from, next)
})

router.afterEach((to, from) => {
  RouterAuthManager.getInstance().handleAfterEach(to, from)
})

export default router
