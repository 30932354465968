/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-bitwise */
/* eslint-disable camelcase */
import axios from 'axios'
import { getFinger, getToken } from '@/utils/token'
import { getArgsFromUrl, matchExpr, reportWebSensors } from '@badam/common-utils'
import { ApiResult, get, post, postReq } from './config'

// ksa
const appSource = process.env.VUE_APP_FLAVOR === 'are' ? 0 : 1

let paySource: number

export function setPaySource(s: number) {
  paySource = s
}
// eslint-disable-next-line no-shadow
export enum payType {
  TapPayment = 5,
  PayerMax = 6,
  Coda = 8,
  DLocal = 9,
  Khalaspay = 10,
  Checkout = 13,
}

export function nameOfPayType(t: payType) {
  return matchExpr(
    t,
    [
      [payType.PayerMax, 'PayerMax'],
      [payType.TapPayment, 'TapPayment'],
      [payType.Coda, 'Coda'],
      [payType.DLocal, 'DLocal'],
      [payType.Khalaspay, 'Khalaspay'],
      [payType.Checkout, 'Checkout'],
    ],
    () => '-'
  )
}

export const USER_TYPE_NORMAL = 0 // 普通用户
export const USER_TYPE_CROP_NO_FLAG = 1 // 合作无标
export const USER_TYPE_CUSTOM = 1 << 2 // 客服

// 经纪人、充值代理
export const USER_TYPE_CROP_FLAG = 1 << 1 // 合作有标
export const USER_TYPE_ANCHOR = 3
export const USER_TYPE_AGENT = 1 << 4 // 经纪人
export const USER_TYPE_JINGJIREN_HEZUOWUBIAO = 17
export const USER_TYPE_JINGJIREN_HEZUOYOUBIAO = 18
export const USER_TYPE_JINGJIREN_CHONGZHIDAILI_ZHUBO = 19

export const USER_TYPE_NENGZHUANZHANG = [USER_TYPE_CROP_FLAG, USER_TYPE_AGENT]
// ---

export const USER_TYPE_CUSTOM_ONLY_OPEN_ROOM = 1 << 3 // 开官方房间的客服
export const USER_TYPE_TRANSACTION_AGENCY = 1 << 10 // 充值代理
export const USER_TYPE_PAYPAL = 1 << 9 // paypal能充值的人

// 检查sawaid
export const checkSawaID = (display_id: number) =>
  get('/api/user/check_display_id/', { display_id, source: appSource }) as unknown as Promise<
    [boolean, ApiResult<any>]
  >

// 获取商品列表
export const getRechargeList = (type: payType = payType.PayerMax, display_id = '') =>
  get('/api/third_party_pay/get_recharge_list_v2/', {
    display_id,
    type,
    source: appSource,
    session_key: getToken(),
  }) as unknown as Promise<[boolean, ApiResult<any>]>

// 订单创建

interface PayerMaxOrderReq {
  display_id: number
  recharge_id: number
  ext?: string
}

const payerMaxBase =
  process.env.VUE_APP_ENV === 'production'
    ? ''
    : getArgsFromUrl('is_release')
    ? 'https://arab-pk-release.badambiz.com'
    : 'https://test20-pk.badambiz.com'

export const createPayerMaxOrder = (p: PayerMaxOrderReq) =>
  postReq(`${payerMaxBase}/api/payer_max/create_pay/`, {
    ...p,
    source: appSource,
  }) as unknown as Promise<[boolean, ApiResult<any>]>

// 订单创建
interface Pay2OrderReq {
  display_id: number
  recharge_id: number
  pay_type?: number
  ext?: number
  cko_token?: string
  cko_source_id?: string
  cko_cvv?: number
}
export const createPay2Order = (p: Pay2OrderReq) =>
  postReq('/api/order/create_coin_pay/', {
    ...p,
    source: paySource || appSource,
  }) as unknown as Promise<[boolean, ApiResult<any>]>

// 登录
export function loginIn(
  display_uid: string,
  password: string,
  device = 'web',
  uuid = (window as any).fingerPrint
) {
  return get('/api/user/account_login/', {
    display_uid,
    password,
    device,
    uuid,
  })
}

// 用户详细信息
export function getInfo(session_key: any) {
  const data = { session_key }
  return post('/api/coin/get_pay_user_info/', data)
}

export function getLastPayType(session_key: any) {
  const data = { session_key }
  return post('/api/web/get_pay_user_info/', data)
}

// ticket login
export function ticketLoginIn(ticket: string) {
  return post('/api/web/ticket_login/', {
    ticket,
  })
}

// facebook login
export function facebookLoginIn(
  user_id: string,
  access_token: string,
  version_code = null,
  device = 'web',
  device_uuid = null,
  sdcard_uuid = null,
  uuid = (window as any).fingerPrint,
  mac = null,
  phone = null
) {
  return post('/api/user/facebook_login/', {
    user_id,
    access_token,
    version_code,
    device,
    device_uuid,
    sdcard_uuid,
    uuid,
    mac,
    phone,
  })
}

// google login
export function googleLoginIn(
  id_token: string,
  version_code = null,
  device = 'web',
  device_uuid = null,
  sdcard_uuid = null,
  uuid = (window as any).fingerPrint,
  mac = null,
  phone = null
) {
  return post('/api/user/google_login/', {
    id_token,
    version_code,
    device,
    device_uuid,
    sdcard_uuid,
    uuid,
    mac,
    phone,
  })
}

const sysInfoBaseUrl = getArgsFromUrl('is_debug')
  ? process.env.VUE_APP_PROXY_URL
  : getArgsFromUrl('is_release')
  ? 'https://arab-pk.badambiz.com/'
  : process.env.VUE_APP_BASE_URL

export function getPkgInfo() {
  return post(`${sysInfoBaseUrl}api/sys/config/`, {
    channel: 'app_pkg_info',
    pure: 1,
  })
}

export function getPayWhiteList() {
  return post(`${sysInfoBaseUrl}api/sys/config/`, {
    channel: 'sawa_official_website_pay_whitelist',
    pure: 1,
  })
}

const larkWebhook =
  'https://open.feishu.cn/open-apis/bot/v2/hook/3a8c5fa3-da25-4b93-ae13-3d409be8dff7'

export const reportToLark = (title: string, text: string) => {
  const data = {
    title,
    content: [
      [
        {
          tag: 'text',
          text,
        },
      ],
    ],
  }
  axios.post(
    larkWebhook,
    {
      msg_type: 'post',
      content: {
        post: {
          zh_cn: data, //
        },
      },
    },
    {
      headers: { 'content-type': 'application/json; charset=utf-8' },
    }
  )
}

// 充值风控 pay2罚款订单
// pay_type    = optional.Enum(group=Pay2PayType, desc='支付类型，khalaspay必填'),
export const createFinePay = (p: { id: string; amount: number; pay_type?: number; ext?: string }) =>
  postReq('/api/order/create_fine_pay/', {
    // display_id,
    ...p,
    source: appSource,
  }) as unknown as Promise<[boolean, ApiResult<any>]>

// 充值风控 创建茄子罚款订单
export const createFinePayermax = (p: { id: string; amount: number; ext?: string }) =>
  postReq('/api/payer_max/create_fine_pay/', {
    // display_id,
    ...p,
    source: appSource,
  }) as unknown as Promise<[boolean, ApiResult<any>]>

// 充值风控 获取罚款详情
export const getFineInfo = (id: number) =>
  get('/api/fine/get_fine_info/', {
    id,
  }) as unknown as Promise<[boolean, ApiResult<any>]>

// 获取用户最后一次成功支付的官网充值渠道
export const getPayUserInfo = () =>
  get('/api/web/get_pay_user_info/', {
    session_key: getToken(),
  }) as unknown as Promise<[boolean, ApiResult<any>]>

export interface RechargeFillRecordReq {
  to_uid: number
  uid?: number
  fill_times: number
  last_fill_time: number
}

// 上报充值填充记录
export const uploadRechargeFillRecord = (p: RechargeFillRecordReq[]) => {
  const pp = p.map((ppp) =>
    JSON.stringify({
      to_uid: ppp.to_uid,
      uid: ppp.uid ? ppp.uid : undefined,
      fill_times: ppp.fill_times,
      last_fill_time: ppp.last_fill_time ? ppp.last_fill_time : undefined,
    })
  )
  return get('/api/web/upload_recharge_fill_record/', {
    session_key: getToken(),
    h5_deviceid: getFinger(),
    records: pp,
  }) as unknown as Promise<[boolean, ApiResult<any>]>
}

interface RechargeFillRecordItem {
  h5_deviceid: string
  to_uid: number
  to_nickname: string
  to_icon: string
  uid: number
  fill_times: number
  first_fill_time: number
  last_fill_time: number
}

interface RechargeFillRecordRes {
  list: RechargeFillRecordItem[]
  has_more: boolean
}

// 获取充值填充记录
export const getRechargeFillRecord = (offset: number, limit: number) =>
  get('/api/web/recharge_fill_record/', {
    session_key: getToken(),
    offset,
    limit,
  }) as unknown as Promise<[boolean, ApiResult<RechargeFillRecordRes>]>

// 检查sawaid
export const checkDuid = (display_id: number) =>
  get('/api/web/check_display_id/', { display_id, source: appSource }) as unknown as Promise<
    [boolean, ApiResult<any>]
  >

interface WebRechargeItem {
  recharge_id: number
  product_id: string
  amount: number
  coin: number
  icon: string
  extra_coin: number
  origin_coin: number
  pay_type: number
  is_enable: boolean
}

interface RechargeListV3Res {
  list: WebRechargeItem[]
  country_code: string
}

// 无登录状态获取充值商品列表-聚合充值点-v3
export const getRechargeListV3 = (display_id?: number) =>
  get('/api/third_party_pay/get_recharge_list_v3/', {
    session_key: getToken(),
    display_id,
    source: appSource,
  }) as unknown as Promise<[boolean, ApiResult<RechargeListV3Res>]>

interface CheckUserStatusRes {
  user_map: Record<string, number>
}

// 官网充值批量查询用户状态
export const checkUserStatus = (uids: number[]) =>
  get('/api/web/check_user_status/', {
    uids,
    source: appSource,
  }) as unknown as Promise<[boolean, ApiResult<CheckUserStatusRes>]>

export type SourceOrderRsp = {
  source_id: string
  last4: number
  bin: number
  to_uid: number
  scheme: string
  fingerprint: string
}

export type SourceId = {
  to_uid: number
  h5_deviceid: string
  is_remember: boolean
  login: boolean
  uid: number
  time_stamp?: number
} & SourceOrderRsp

export type SourceIdRsp = {
  items: SourceId[]
}

export const getCardSourceByOrder = (order_id: string) =>
  get('/api/web/get_card_source_by_order/', { order_id }) as unknown as Promise<
    [boolean, ApiResult<SourceOrderRsp>]
  >

export const getSourceIds = () =>
  get('/api/web/get_card_source/', {
    session_key: getToken(),
  }) as unknown as Promise<[boolean, ApiResult<SourceIdRsp>]>

export const uploadSourceId = (
  source_id: string,
  last4: number,
  bin: number,
  scheme: string,
  to_uid: number,
  h5_deviceid: string,
  is_remember: boolean,
  fingerprint: string
) =>
  get('/api/web/upload_card_source/', {
    session_key: getToken(),
    source_id,
    last4,
    bin,
    scheme,
    to_uid,
    h5_deviceid,
    is_remember,
    fingerprint,
  }) as unknown as Promise<[boolean, ApiResult<any>]>

export const removeSourceId = (fingerprint: string) =>
  get('/api/web/delete_card_source/', {
    fingerprint,
    session_key: getToken(),
  }) as unknown as Promise<[boolean, ApiResult<any>]>
