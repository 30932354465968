/* eslint-disable camelcase */
import { SourceId } from '@/api'
import { LOGIN_TOKEN_KEY } from '@/utils/consts'
import payManager, {
  LocalRechargeFillRecordItem,
  PayPageStatus,
  UidStatus,
} from '@/utils/pay-manager'
import { kLoginSource } from '@/utils/step-report'
import { getValue } from '@/utils/token'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface PkgInfo {
  ksa_google_url: string
  are_google_url: string
  ksa_apple_url: string
  are_apple_url: string
  ksa_android_url: string
  are_android_url: string
  ksa_private_url: string
  are_private_url: string
  audit_room_url: string
  are_huawei_url: string
  ksa_huawei_url: string
  website_version: string
  recharge_fill_record_time_limit: number
  recharge_fill_record_num_limit: number
  cko_new_rate: number
  cko_new_whitelist: number[]
  cko_card_record_time_limit: number
}

export type PullSid = {
  order_id: string
}

// eslint-disable-next-line no-shadow
export enum FinishGetSidState {
  成功 = 1,
  失败 = 0,
  未开始 = 2,
}

interface StoreState {
  isLogin: boolean
  loginType: kLoginSource
  pkgInfo: PkgInfo
  nowRoute: string
  session_key: string
  login_position: '充值按钮上方' | '顶部banner' | ''
  userMap: Record<string, UidStatus>
  historyList: LocalRechargeFillRecordItem[]
  banList: LocalRechargeFillRecordItem[]
  status: PayPageStatus
  statusInStorage: PayPageStatus
  showPayModal: boolean
  rechargeAccount: LocalRechargeFillRecordItem
  sids: SourceId[]
  realSids: SourceId[]
  pullingSid: PullSid | null
  hasFinishGetSourceId: boolean
  finishiGetSourceIdState: FinishGetSidState
}

const store = new Vuex.Store({
  state: {
    isLogin: false,
    loginType: '',
    pkgInfo: {} as PkgInfo,
    session_key: getValue(LOGIN_TOKEN_KEY) || '',
    nowRoute: window.location.pathname,
    login_position: '',
    userMap: {} as Record<string, UidStatus>,
    historyList: [] as LocalRechargeFillRecordItem[],
    banList: [] as LocalRechargeFillRecordItem[],
    status: '' as PayPageStatus,
    statusInStorage: payManager.getStatus() as PayPageStatus,
    showPayModal: false,
    rechargeAccount: payManager.getRechargeAccount() || ({} as LocalRechargeFillRecordItem),
    sids: [] as SourceId[],
    realSids: [] as SourceId[],
    hasFinishGetSourceId: true,
    pullingSid: null,
    finishiGetSourceIdState: FinishGetSidState.未开始,
  },
  mutations: {
    setPkgInfo(state: StoreState, info: PkgInfo) {
      state.pkgInfo = info
    },
  },
  actions: {},
  modules: {},
})

export default store
;(window as any).store = store
export function setPkgInfo(info: PkgInfo) {
  store.commit('setPkgInfo', info)
}
