/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import {
  checkDuid,
  checkUserStatus,
  getRechargeFillRecord,
  getRechargeListV3,
  RechargeFillRecordReq,
  uploadRechargeFillRecord,
} from '@/api'
import { showToast } from '@/components/toast/toast.vue'
import store from '@/store'
import { handleApiResult, _T } from '@badam/common-utils'
import { getStorage, setStorage } from '@badam/common-utils/src/func'
import _ from 'lodash'
import { getFinger, getUid, initFinger, setFinger } from './token'

export enum UidStatus {
  封禁 = 2,
  注销 = 1,
  注销中 = 4,
  不存在 = 3,
  正常 = 0,
}

export enum PayPageStatus {
  输入账号 = 'enter',
  切换 = 'switch',
  支付 = 'pay',
  没有 = '',
}

export interface LocalRechargeFillRecordItem {
  h5_deviceid: string
  to_uid: number
  to_uid_status?: UidStatus
  to_display_uid: number
  to_nickname: string
  to_icon: string
  uid: number
  fill_times: number
  last_upload_fill_times: number
  first_fill_time: number
  last_fill_time: number
}

const LocalRechargeFillRecordKey = 'recharge_fill_record'

const StatusKey = 'pay_status_key'

const StatusInnerLastKey = 'pay_status_inner_last_key'

const GoToLoginKey = 'pay_go_to_login_key'

export const ThisIsTicket = 'ThisIsTicket'

export const LastRechargeTypeKey = 'LastRechargeTypeKey'

const LOG_TAG = 'PayManager'

export const HISTORY_LAST_LENGTH_KEY = 'HISTORY_LAST_LENGTH_KEY'

export const IS_FIRST_TIME_SHOW_HALFPAGE = 'IS_FIRST_TIME_SHOW_HALFPAGE'

export function getSStorage(key: string) {
  try {
    return JSON.parse(sessionStorage.getItem(key) || '""')
  } catch (e) {
    return ''
  }
}

export function setSStorage(key: string, value: any) {
  sessionStorage.setItem(key, JSON.stringify(value))
}

class PayManager {
  static instance: PayManager

  static getInstance() {
    if (!this.instance) {
      this.instance = new PayManager()
    }
    return this.instance
  }

  getLocalRechargeFillRecord() {
    const r = getStorage(LocalRechargeFillRecordKey) || []
    const rr = r.filter((i: any) => i.to_uid)
    return rr
  }

  sleep(t: number) {
    return new Promise((r) => {
      setTimeout(() => {
        r(true)
      }, t)
    })
  }

  async getServerRechargeFillRecord() {
    let list: any[] = []
    async function getServerRechargeFillRecord2(
      offset = 0,
      limit = store.state.pkgInfo.recharge_fill_record_num_limit,
      time = 0
    ): Promise<any> {
      const [success, { result, data }] = await getRechargeFillRecord(offset, limit)

      if (success && result === 0) {
        if (data.list && data.list.length > 0) {
          const map = _.groupBy(data.list, 'to_uid')
          if (offset === 0) {
            list = Object.values(map)
              .map((recordItems: any) => {
                recordItems.sort((a: any, b: any) => b.last_fill_time - a.last_fill_time)
                return recordItems[0]
              })
              .sort((a, b) => b.last_fill_time - a.last_fill_time)
            console.warn(LOG_TAG, '初始', list.length, offset)
          } else {
            list = list.concat(
              Object.values(map)
                .map((recordItems: any) => {
                  recordItems.sort((a: any, b: any) => b.last_fill_time - a.last_fill_time)
                  return recordItems[0]
                })
                .sort((a, b) => b.last_fill_time - a.last_fill_time)
            )
            const itsMap = _.groupBy(list, 'to_uid')
            list = Object.values(itsMap)
              .map((recordItems: any) => {
                recordItems.sort((a: any, b: any) => b.last_fill_time - a.last_fill_time)
                return recordItems[0]
              })
              .sort((a, b) => b.last_fill_time - a.last_fill_time)
            console.warn(LOG_TAG, '添加', list.length, offset)
          }
        }
      }
      console.warn(LOG_TAG, 'llstttstt', list.length, offset)

      if (
        list.length < store.state.pkgInfo.recharge_fill_record_num_limit &&
        data &&
        data.has_more &&
        time < 8
      ) {
        return getServerRechargeFillRecord2(offset + limit, limit, time + 1)
      }
      return list.slice(0, 15)
    }
    return getServerRechargeFillRecord2()
  }

  saveLocalRechargeFillRecords(items: LocalRechargeFillRecordItem[]) {
    return setStorage(LocalRechargeFillRecordKey, items)
  }

  findRechargeFillRecord(to_uid: number) {
    const records = this.getLocalRechargeFillRecord()
    const index = records.findIndex(
      (record: LocalRechargeFillRecordItem) => record.to_uid === to_uid
    )

    return {
      index,
      record: records[index],
    }
  }

  async ifUploadRechargeRillRecord(items: RechargeFillRecordReq[]) {
    let its = items.filter((i) => (i.uid && i.uid === getUid()) || !i.uid)
    // its = its = items.map((i) => ({ ...i, fill_times: 1 })) // 账号匹配不上的不上传
    console.warn('PayManager', 'records', its, items, getUid())
    if (its.length > 0) {
      const finger = getFinger()
      if (!finger) {
        await setFinger()
      }
      const r = await uploadRechargeFillRecord(its)
      if (handleApiResult(r)) {
        this.getHistoryList()
      }
    }
  }

  isUserDataInRecords(
    userData: LocalRechargeFillRecordItem,
    records: LocalRechargeFillRecordItem[]
  ) {
    for (let i = 0; i < records.length; i += 1) {
      const r = records[i]
      if (r.to_uid === userData.to_uid) {
        return true
      }
    }
    return false
  }

  async saveRechargeFillRecord(userData: LocalRechargeFillRecordItem, needUpload = true) {
    console.warn(LOG_TAG, 'save', userData)
    userData.last_fill_time = Math.floor(Date.now() / 1000)
    userData.uid = +getUid() || 0
    userData.h5_deviceid = getFinger()
    if (!userData.fill_times) {
      userData.fill_times = 0
    }
    userData.fill_times += 1

    if (getUid() && needUpload) {
      userData.last_upload_fill_times = userData.last_fill_time
      const uid = getUid() ? getUid() : undefined
      await this.ifUploadRechargeRillRecord([
        {
          to_uid: userData.to_uid,
          uid,
          fill_times: 1,
          last_fill_time: userData.last_fill_time,
        },
      ])
    }

    const records = this.getLocalRechargeFillRecord()
    const { index } = this.findRechargeFillRecord(userData.to_uid)

    if (index !== -1) {
      records.splice(index, 1)
    }

    console.warn(LOG_TAG, 'save 2', records)

    records.unshift(userData)
    this.saveLocalRechargeFillRecords(records)
  }

  async getRechargeList(duid?: number) {
    const [success, { result, data }] = await getRechargeListV3(duid)
    const list: Record<string, any>[] = []

    if (success && result === 0) {
      const map = _.groupBy(data.list, 'amount')

      Object.keys(map)
        .sort((a, b) => Number(a) - Number(b))
        .forEach((amount) => {
          // eslint-disable-next-line max-len
          const items = map[amount].sort(
            (item1: Record<string, any>, item2: Record<string, any>) =>
              item2.pay_type - item1.pay_type
          )

          list.push({
            amount,
            items,
          })
        })

      const len = list.length % 3
      for (let i = 0; i < 3 - len; i += 1) {
        list.push({})
      }
    }

    return list
  }

  getRechargeAccount(): LocalRechargeFillRecordItem {
    let r = this.getLocalRechargeFillRecord()
    r = r.filter((i: any) => i.to_uid)
    return r[0] || ({} as LocalRechargeFillRecordItem)
  }

  checkDuid(duid: number) {
    return checkDuid(duid).then(async (res) => {
      if (res[0]) {
        if (res[1].result === -1 || res[1].result === 10001 || res[1].result === 20139) {
          showToast(_T('没有匹配结果，请检查输入是否正确'))
        } else if (res[1].result === 20001) {
          showToast(_T('该账号封禁中，无法给该账号充值'))
        } else if (res[1].result === 20186) {
          showToast(_T('该账号被注销，无法给该账号充值'))
        } else if (res[1].result === 0) {
          const userData = res[1].data
          const r = await checkUserStatus([userData.uid])
          if (handleApiResult(r)) {
            const [, { data }] = r
            const { user_map } = data
            if (user_map[userData.uid] === UidStatus.注销中) {
              showToast(_T('该账号注销中，无法给该账号充值'))
            }
            if (user_map[userData.uid] === UidStatus.正常) {
              let { record } = this.findRechargeFillRecord(userData.uid)
              record = {
                ...record,
                h5_deviceid: getFinger(),
                to_uid: userData.uid,
                to_display_uid: duid,
                to_nickname: userData.nickname,
                to_icon: userData.icon,
                uid: getUid() || 0,
                fill_times: 0,
                last_upload_fill_times: 0,
                first_fill_time: Math.floor(Date.now() / 1000),
                last_fill_time: Math.floor(Date.now() / 1000),
              }

              this.saveRechargeFillRecord(record)
              return [true, record]
            }
          }
        } else {
          showToast(_T('没有匹配结果，请检查输入是否正确'))
          console.warn(LOG_TAG, 'check duid', res[1].result, res[1].message)
        }
      }
      return [true, null]
    })
  }

  getStatus() {
    return getSStorage(StatusKey)
  }

  setStatus(status: PayPageStatus) {
    console.warn(LOG_TAG, 'set status', status)
    setSStorage(StatusKey, status)
  }

  getLastStatus() {
    return getSStorage(StatusInnerLastKey)
  }

  setLastStatus(status: PayPageStatus) {
    setSStorage(StatusInnerLastKey, status)
  }

  async checkUidStatus(duid: number) {
    const res = await checkDuid(duid)
    let r = UidStatus.正常
    if (!handleApiResult(res)) {
      const [, { result }] = res
      if (result === 20001) {
        r = UidStatus.封禁
      } else if (result === 20186) {
        r = UidStatus.注销
      } else if (result === -1 || result === 10001) {
        r = UidStatus.不存在
      }
    } else {
      const [, { data }] = res
      const userData = data
      const rr = await checkUserStatus([userData.uid])
      if (handleApiResult(rr)) {
        const [, { data }] = rr
        const { user_map } = data
        if (user_map[userData.uid] === UidStatus.注销中) {
          r = UidStatus.注销中
        }
      }
    }
    return r
  }

  showBanTip(status?: number) {
    if (status === UidStatus.注销中) {
      showToast(_T('该账号注销中，无法给该账号充值'))
    } else if (status === UidStatus.注销) {
      showToast(_T('该账号被注销，无法给该账号充值'))
    } else if (status === UidStatus.封禁) {
      showToast(_T('该账号封禁中，无法给该账号充值'))
    } else if (status === UidStatus.不存在) {
      showToast(_T('该账号被注销，无法给该账号充值'))
    }
  }

  setPayGoToLogin(num: number) {
    setSStorage(GoToLoginKey, num)
  }

  getPayGoToLogin() {
    return getSStorage(GoToLoginKey)
  }

  async getHistoryList() {
    const localList = this.getLocalRechargeFillRecord()
    let historyList = []

    if (getUid()) {
      const list = await this.getServerRechargeFillRecord()

      const serverList = list.map((item: Record<string, any>) => {
        item.last_upload_fill_times = item.last_fill_time
        return item as LocalRechargeFillRecordItem
      })

      historyList = [...serverList]
      historyList = _.uniqBy(historyList, 'to_uid')
      historyList = historyList.filter((item: LocalRechargeFillRecordItem) => item.to_uid)
      if (historyList.length > 0) {
        this.saveLocalRechargeFillRecords(historyList)
      }
      console.warn('PayManager', 'histroyist 111', historyList, serverList)
    } else {
      // eslint-disable-next-line max-len
      historyList = localList
      const list = historyList
        .filter(
          (item: LocalRechargeFillRecordItem) =>
            Date.now() - item.last_fill_time * 1000 <=
            store.state.pkgInfo.recharge_fill_record_time_limit * 60 * 1000
        )
        .filter((item: LocalRechargeFillRecordItem) => item.to_uid)
      if (list.length === 0) {
        historyList = [historyList[0]]
      } else {
        historyList = list
      }
      console.warn('PayManager', 'histroyist 222', historyList, localList)
    }

    const uids = historyList.map((record: LocalRechargeFillRecordItem) => record.to_uid)
    const [success, { result, data }] = await checkUserStatus(uids)

    console.warn('PayManager', 'histroyist', historyList, localList)

    if (success && result === 0) {
      let allowList: LocalRechargeFillRecordItem[] = []
      const banList: LocalRechargeFillRecordItem[] = []

      const { user_map } = data

      store.state.userMap = { ...store.state.userMap, ...user_map }

      historyList.forEach((record: LocalRechargeFillRecordItem) => {
        const status = store.state.userMap[record.to_uid]
        if (status === UidStatus.正常) {
          // const curUser = currentList[0] || ({} as LocalRechargeFillRecordItem)
          // if (curUser.to_uid !== record.to_uid) {
          allowList.push({ ...record, to_uid_status: status })
          // }
        } else {
          if (status === UidStatus.注销中 || status === UidStatus.封禁) {
            banList.push({
              ...record,
              to_uid_status: status,
            })
          }
        }
      })

      allowList = allowList.slice(0, store.state.pkgInfo.recharge_fill_record_num_limit)
      store.state.historyList = allowList
      store.state.banList = banList
    } else {
      historyList = historyList.slice(0, store.state.pkgInfo.recharge_fill_record_num_limit)
      store.state.historyList = historyList
    }
  }
}

const payManager = PayManager.getInstance()

export default payManager
