
import Vue from 'vue'

const Toast = Vue.extend({
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showWrap: true, // 是否显示组件
      showContent: true, // 作用:在隐藏组件之前,显示隐藏动画
    }
  },
})

export const showToast = (text: string, duration = 4000) => {
  const toast = new Toast({ el: document.createElement('div') }) as any
  const el = toast.$el
  toast.text = text
  document.body.appendChild(el)
  // 提前 250ms 执行淡出动画
  setTimeout(() => {
    toast.showContent = false
  }, duration - 1250)
  // 过了 duration 时间后隐藏整个组件
  setTimeout(() => {
    toast.showWrap = false
  }, duration)
}

export function registryToast() {
  Vue.prototype.$toast = showToast
  ;(window as any).toast = showToast
}

export default Toast
