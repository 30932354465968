import { NavigationGuardNext, Route } from 'vue-router'

class RouterAuthManager {
  static instance: RouterAuthManager

  static getInstance() {
    if (!RouterAuthManager.instance) {
      RouterAuthManager.instance = new RouterAuthManager()
    }
    return RouterAuthManager.instance
  }

  handleBeforeEach(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    next()
  }

  handleAfterEach(to: Route, from: Route) {
    console.warn('after each')
  }
}

export default RouterAuthManager
