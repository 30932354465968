/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-bitwise */
import _ from 'lodash'
import { getArgsFromUrl, reportEvent } from '@badam/common-utils'
import store from '@/store'
import { USER_TYPE_NENGZHUANZHANG } from '@/api'
import qs from 'qs'
import { getStorage, setStorage } from '@badam/common-utils/src/func'
import router from '../router/index'
import { getUserInfo, getUid, getFinger } from './token'

export function goToAndroid() {
  // if (process.env.VUE_APP_FLAVOR === 'are') {
  //   window.open(store.state.pkgInfo.are_android_url)
  // } else {
  //   window.open(store.state.pkgInfo.ksa_android_url)
  // }
  const kanka_android_url = 'https://h5.kanka.social/apk/kanka/30502.apk'
  window.open(kanka_android_url)
}

export function goToHuawei() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    window.open(store.state.pkgInfo.are_huawei_url)
  } else {
    window.open(store.state.pkgInfo.ksa_huawei_url)
  }
}

export function goToAndroid2() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    ;(window as any).JavaBridgeObj.router(
      `${store.state.pkgInfo.are_android_url}?isOutBrowser=true`
    )
  } else {
    ;(window as any).JavaBridgeObj.router(
      `${store.state.pkgInfo.ksa_android_url}?isOutBrowser=true`
    )
  }
}

export function goToApple() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    window.open(store.state.pkgInfo.are_apple_url)
  } else {
    window.open(store.state.pkgInfo.ksa_apple_url)
  }
}

export function goToApple2() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    location.href = store.state.pkgInfo.are_apple_url
  } else {
    location.href = store.state.pkgInfo.ksa_apple_url
  }
}

export function goToApple3() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    ;(window as any).JavaBridgeObj.router(`${store.state.pkgInfo.are_apple_url}?isOutBrowser=true`)
  } else {
    ;(window as any).JavaBridgeObj.router(`${store.state.pkgInfo.ksa_apple_url}?isOutBrowser=true`)
  }
}

export function goToGoogle() {
  // if (process.env.VUE_APP_FLAVOR === 'are') {
  //   window.open(store.state.pkgInfo.are_google_url)
  // } else {
  //   window.open(store.state.pkgInfo.ksa_google_url)
  // }
  const kanka_google_url = 'https://play.google.com/store/apps/details?id=com.badambiz.pk.kanka'
  window.open(kanka_google_url)
}

export function goToGoogle2() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    location.href = store.state.pkgInfo.are_google_url
  } else {
    location.href = store.state.pkgInfo.ksa_google_url
  }
}

export function goToGoogle3() {
  if (process.env.VUE_APP_FLAVOR === 'are') {
    ;(window as any).JavaBridgeObj.router(`${store.state.pkgInfo.are_google_url}&isOutBrowser=true`)
  } else {
    ;(window as any).JavaBridgeObj.router(`${store.state.pkgInfo.ksa_google_url}&isOutBrowser=true`)
  }
}

export function goToPrivate() {
  console.warn('go to private', store.state.pkgInfo)
  if (process.env.VUE_APP_FLAVOR === 'are') {
    window.location.href = store.state.pkgInfo.are_private_url
  } else {
    // window.location.href = store.state.pkgInfo.ksa_private_url
    window.location.href = 'https://h5.kanka.social/docs/kanka/privacy_policy_kanka.html'
  }
}

export function goToAuditRoom() {
  window.open(store.state.pkgInfo.audit_room_url)
}

export function shiNengZhuanzhangDeRenDeQizhongYizhong(v: number, v2: number) {
  return (v & v2) !== 0
}

export function youNengliZhuanzhang(v: number) {
  let flag = false
  USER_TYPE_NENGZHUANZHANG.forEach((value) => {
    if (shiNengZhuanzhangDeRenDeQizhongYizhong(v, value)) {
      flag = true
    }
  })
  return flag
}

const getRequest = () => {
  // 获取url中"?"符后的字串
  const url = window.location.search
  const theRequest: any = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1)
    const strs = str.split('&')
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

const getHashParamString = () => {
  // 获取url中"#"里面，‘?’符后的字符串
  const v = window.location.hash
  if (!v) {
    return ''
  }
  const i = v.indexOf('?')
  if (i === -1) {
    return ''
  }
  return v.slice()
}

type paramsProp = {
  name: string
  [propName: string]: any
}

export function routerPush(params: paramsProp) {
  // params 全部都是name
  // 获取现在url上的参数
  const requests = getRequest()
  // eslint-disable-next-line no-param-reassign
  params.query = requests
  if (router.mode) {
    // fix hash模式下丢hash qs 问题
    params.hash = getHashParamString()
  }
  router.push(params).catch(() => {
    // nothing do 路由跳转拦截都会报的
  })
  // 同步下陆游状态到store
  store.state.nowRoute = `/${params.name}`
}

export function routerBack() {
  router.back()
}

const address = [
  'head.ghsau.badambiz.com', // 不支持
  'ghsau-design-cdn.badambiz.com', // 不支持
]

export function changeBalootIcon(url: string) {
  console.log(url)
  if (
    url.indexOf('http://head.ghsau.badambiz.com') !== -1 ||
    url.indexOf('http://ghsau-design-cdn.badambiz.com') !== -1
  ) {
    url = url.replace('http://head.ghsau.badambiz.com', 'https://ghsau.badambiz.com')
    url = url.replace('http://ghsau-design-cdn.badambiz.com', 'https://ghsau.badambiz.com')
    return url
  }
  return url
}

export async function reportPayCb() {
  await reportEvent('web_pay2_order_cb', {
    str_param3: JSON.stringify({
      user: getUserInfo(),
    }),
  })
}

export function getArgsFromUrlHash(key: string) {
  if (window.location.hash.indexOf('?') !== -1) {
    const s1 = location.hash.split('#').slice(-1)[0] || ''
    const s2 = s1.split('?')[1] || ''
    const obj = qs.parse(s2, { ignoreQueryPrefix: true })
    console.log('args>>', obj, s2, s1)
    return obj[key] ? obj[key] : getArgsFromUrl(key)
  }
  return getArgsFromUrl(key)
}

export function getWebsiteVersion() {
  return process.env.VUE_APP_WEBSITE_VERSION
}

export default {
  goToAndroid,
  goToApple,
  goToGoogle,
}
