export const LOGIN_TOKEN_KEY = 'isawa_website_login_token_2'
export const LAST_INPUT_ID = 'isawa_website_last_input_id'
export const LOGIN_TICKET_KEY = 'isawa_website_login_ticket_2'
export const LOGIN_TYPE_KEY = 'isawa_website_login_type_2'
export const USER_INFO_KEY = 'isawa_USER_INFO_KEY_2'
export const NANO_ID_KEY = 'isawa_official_website_nanoid_3'
export const FINGER_KEY = 'isawa_official_website_finger_2'
export const PAY_TIME_COST_KEY = 'isawa_PAY_TIME_COST_KEY'
export const FINE_TIME_COST_KEY = 'isawa_FINE_TIME_COST_KEY'
export const LOGIN_GO_POSITION_KEY = 'isawa_LOGIN_GO_POSITION_KEY'
export const PAY_INPUT_SOURCE = 'isawa_pay_input_source'
